<template >
  <footer class="bg-dark text-light">
    <div class="container pt-4">
      <div class="row">
        <div class="col-md-3 d-flex flex-column justify-content-between align-items-start">
          <div>公司名称：成都小车飞象环保科技有限公司</div>
          <div>地址： 成都高新区玉林西路60号1层</div>
          <div>电话： 13618136572</div>
          <div>邮箱： 453481247@qq.com</div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-3">
          <h6>微信公众号</h6>
          <img src="../assets/img/qrcode.png">
        </div>

      </div>
    </div>
    <div class="bg-dark text-center p-3">
      <span class="mb-0">© 2021 成都小车飞象环保科技有限公司. All Rights Reserved.</span>
      <a href="https://beian.miit.gov.cn">备案号：蜀ICP备2020026820号</a>
    </div>
  </footer>

</template>
<script>
export default {
  name: 'Footer'
}
</script>
<style scope>
	
a:link {color: white; text-decoration:none;} /* //未访问：蓝色、无下划线 */ 
a:active:{color: white; } /* //激活：红色 */
a:visited {color:white;text-decoration:none;} /* //已访问：紫色、无下划线 */ 
a:hover {color: white; text-decoration:none;} /* //鼠标移近：红色、下划线 */
	
</style>